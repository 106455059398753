





















import Vue from 'vue'
import ODataTable from '@/components/ODataTable.vue'
import EventDetails from './EventDetails.vue'
import IODataTableHeader from '@/models/IODataTableHeader'

export default Vue.extend({
  components: {
    ODataTable,
    EventDetails
  },
  data() {
    return {
      expanded: []
    }
  },
  computed: {
    headers(): IODataTableHeader[] {
      return [
        {
          text: 'Name',
          value: 'displayName',
          searchable: true
        },
        {
          text: 'Description',
          value: 'description',
          searchable: true
        },
        {
          text: 'Date',
          value: 'modifiedDate',
          format: 'datetime'
        },
        {
          text: 'By',
          value: 'modifiedBy'
        }
      ]
    }
  },
  methods: {
    onRowClick(item, tableEvent) {
      tableEvent.expand(!tableEvent.isExpanded)
    }
  }
})
