























import Vue from 'vue'
import ISystemEvent from '../models/ISystemEvent'
import SystemEventModel from '../models/SystemEvent'

export default Vue.extend({
  props: {
    value: Object as () => ISystemEvent
  },
  computed: {
    event(): SystemEventModel {
      return new SystemEventModel(this.value)
    }
  }
})
